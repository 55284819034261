import $ from 'jquery';
window.$ = $;
window.jQuery = $;
if (global) global.jQuery = $;

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import * as popper from '@popperjs/core';
import * as moment from 'moment';

import 'channels';
import 'controllers';
import '../application/stylesheets/application.css.scss';
import '../stylesheets/application.scss';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import { Application } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

const application = Application.start()

// Configure Stimulus development experience
application.debug = process.env.RAILS_ENV === "development"
application.consumer = consumer
window.Stimulus   = application

export { application }
